import { ContentSection } from "../components/sections/SimpleContent"
import {
  Image,
  ProductInterface as Props,
  SEO,
  PageSection,
  CartProduct,
  Price,
} from "../ts/interfaces"
import { getCartProduct, getStripeId } from "../utils/stripejs"
import { isHeadset } from "./shared/calcStateItems"
import { Currency } from "./types/checkoutTypes"

export class Product {
  id: string
  stripeId?: string
  stripeTestId?: string
  slug: string
  seo?: SEO
  name: string
  featuredImage: Image
  gallery: Image[]
  sections: PageSection[]
  subHeading: string
  description: string
  shortDescription: string
  isBilledMonthly: boolean
  isBilledYearly: boolean
  CAD_List_Price: number
  CAD_Discount: number
  USD_List_Price: number
  USD_Discount: number
  priceHeading?: string
  outOfStock?: boolean
  postPurchaseContent?: ContentSection
  subscriptionPeriod: "yearly" | "monthly"
  relatedProductPriceLink?: string
  relatedProducts?: {
    id: string
  }[]
  canUsOnly?: boolean
  noCoupons?: boolean
  relatedPriceTxt: string = ""

  stripeProduct: CartProduct | null
  actualstripeId?: string

  constructor(props: Props) {
    this.id = props.id
    this.stripeId = props.stripeId
    this.stripeTestId = props.stripeTestId
    this.slug = props.slug
    this.seo = props.seo
    this.name = props.name
    this.featuredImage = props.featuredImage
    this.gallery = props.gallery
    this.sections = props.sections
    this.subHeading = props.subHeading
    this.description = props.description
    this.shortDescription = props.shortDescription
    this.isBilledMonthly = props.isBilledMonthly
    this.isBilledYearly = props.isBilledYearly
    this.CAD_List_Price = props.CAD_List_Price
    this.USD_List_Price = props.USD_List_Price
    this.CAD_Discount = props.CAD_Discount
    this.USD_Discount = props.USD_Discount
    this.priceHeading = props.priceHeading
    this.outOfStock = props.outOfStock
    this.postPurchaseContent = props.postPurchaseContent
    this.subscriptionPeriod = props.subscriptionPeriod
    this.relatedProductPriceLink = props.relatedProductPriceLink
    this.relatedProducts = props.relatedProducts
    this.canUsOnly = props.canUsOnly
    this.noCoupons = props.noCoupons

    const stripeId = getStripeId(props.stripeId, props.stripeTestId)
    const stripeProduct = getCartProduct(stripeId)

    if (stripeProduct) {
      props.CAD_List_Price =
        (stripeProduct?.prices?.cad?.unit_amount ?? 0) + props.CAD_Discount
      props.USD_List_Price =
        (stripeProduct?.prices?.usd?.unit_amount ?? 0) + props.USD_Discount
    } else {
      props.CAD_List_Price = props.CAD_Discount
      props.USD_List_Price = props.USD_Discount
    }
    this.stripeProduct = stripeProduct
    this.actualstripeId = stripeId
  }

  isRecurring(): boolean {
    return this.stripeProduct?.prices?.cad?.type === "recurring"
  }

  getDiscount(currency: Currency): number {
    let discount = this.USD_Discount

    if (currency === "cad") {
      discount = this.CAD_Discount
    }
    return discount
  }

  getPriceAmt(currency: Currency, withDiscount = true): number | null {
    const price = this.getPrice(currency)
    if (!price) {
      return null
    }

    return withDiscount
      ? price.unit_amount + this.getDiscount(currency)
      : price.unit_amount
  }

  getPrice(currency: Currency): Price | null {
    if (!this.stripeProduct) {
      return null
    }
    return this.stripeProduct.prices[currency] ?? null
  }

  isHeadset(): boolean {
    return isHeadset(this.stripeProduct)
  }

  isOutOfRegion(country: string): boolean {
    return this.canUsOnly === true && country !== "CAN" && country !== "USA"
  }

  isNoCoupon(): boolean {
    return this.noCoupons === true
  }

  setRelatedPriceText(text: string) {
    this.relatedPriceTxt = text
  }

  getRelatedProduct(
    allProducts: Product[],
    country: string
  ): Product | undefined {
    const hasRelatedPrice =
      this.relatedProductPriceLink && (this.relatedProducts?.length ?? 0) > 0

    const relProd = hasRelatedPrice
      ? allProducts.find(p => p.id === this.relatedProducts![0].id)
      : undefined
    relProd?.setRelatedPriceText(this.relatedProductPriceLink!)
    return relProd?.isOutOfRegion(country) === false ? relProd : undefined
  }
}
